const getDefaultState = () => {
	return {
		province_options: [],
		regency_options: [],
		district_options: [],
	}
}

const default_state = getDefaultState()

export { default_state }