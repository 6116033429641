<template>
    <modal-component :modal_status="modal" :modal_footer="'custom-bg-03'">
        <template v-slot:modal_header>
            <div class="d-flex justify-content-center align-items-center modal-title custom-font-lg custom-font-bold custom-font-color-02 w-100">
                <img src="@/assets/image/logo.png" class="custom-navbar-logo me-2">
                MASUK
            </div>
            <button type="button" class="btn-close" @click="close_modal"/>
        </template>
        <template v-slot:modal_body>
            <form @submit.prevent="signInEmail" class="px-3">
                <div class="mb-3" :class="validation.firstError('form.email') ? 'custom-form-error' : ''">
                    <label class="form-label">Email</label>
                    <div class="input-group">
                        <input type="email" class="form-control custom-form-control" placeholder="Email" v-model="form.email">
                    </div>
                    <div class="custom-form-error-message">{{ validation.firstError('form.email') }}</div>
                </div>
                <div class="mb-3" :class="validation.firstError('form.password') ? 'custom-form-error' : ''">
                    <label class="form-label">Password</label>
                    <div class="input-group">
                        <input :type="show_password ? 'text' : 'password'" class="form-control custom-form-control" placeholder="Password" autocomplete="off" v-model="form.password">
                        <div class="input-group-append">
                            <button type="button" class="btn custom-form-control-append-button" @click="password_toggle"><i class="fas fa-eye"></i></button>
                        </div>
                    </div>
                    <div class="custom-form-error-message">{{ validation.firstError('form.password') }}</div>
                </div>
                <button type="submit" class="btn custom-btn-02 w-100 mt-3">Masuk</button>
            </form>
            <div class="custom-border my-4">
                <p class="custom-border-text">Atau Masuk Dengan</p>
            </div>
            <div class="px-3">
                <button type="button" class="btn custom-btn-outline-secondary custom-font-bold w-100 d-flex justify-content-center mb-3" @click="signInGoogle()">
                    <img src="@/assets/image/google.png" class="me-2" style="width: auto; height: 24px;">
                    Google
                </button>
            </div>
        </template>
        <template v-slot:modal_footer>
            <div class="w-100 text-center">
                <div>Belum Memiliki Akun? <a href="javascript:void(0)" class="custom-font-color-02" @click="open_sign_up">Daftar Sekarang</a></div>
            </div>
        </template>
    </modal-component>
</template>

<script>
    import ModalComponent from '@/components/external/ModalComponent'
    import { mapGetters } from 'vuex'
    import { Validator } from 'simple-vue3-validator'
    import Swal from 'sweetalert2'

	export default {
        emits: ['open_sign_up'],
        components: {
            'modal-component': ModalComponent,
        },
        data() {
            return {
                modal: false,
                show_password: false,
            }
        },
        computed: {
            ...mapGetters({
                form: 'auth/getSignInForm',
            }),
        },
        validators: {
            'form.email': function(value){
                return Validator.value(value).required('Email is required.').email('Email is invalid.')
            },
            'form.password': function(value){
                return Validator.value(value).required('Password is required.')
            }
        },
        methods: {
            open_modal(){
                this.modal = true
            },
            open_sign_up(){
                this.close_modal()
                this.$emit('open_sign_up')
            },
            close_modal(){
                this.modal = false
                this.$store.dispatch('auth/resetSignInForm')
                this.validation.reset()
            },
            password_toggle(){
                this.show_password = !this.show_password
            },
            signInEmail(){
                let parent = this
                parent.$validate().then(function (success){
                    if(success){
                        let data = {
                            email: parent.form.email,
                            password: parent.form.password
                        }
                        parent.$store.dispatch('auth/signInEmail', data).then((response) => {
                            if(response.status){
                                parent.login(response.data)
                            }
                            else{
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: response.message,
                                    confirmButtonColor: "#FF6464",
                                    confirmButtonText: `Close`,
                                })
                            }
                        })
                    }
                })
            },
            signInGoogle(){
                let parent = this
                parent.$store.dispatch('auth/signInGoogle').then((response) => {
                    if(response.status){
                        parent.register(response.data)
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.message,
                            confirmButtonColor: parent.$root.swal('errorButtonColor'),
                            confirmButtonText: parent.$root.swal('closeButtonText'),
                        })
                    }
                })
            },
            register(value){
                let data = {
                    email: value.email,
                    uid: value.uid,
                }
                this.$store.dispatch('auth/register', data).then((response) => {
                    if(response.status){
                        this.login(value)
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.message,
                            confirmButtonColor: this.$root.swal('errorButtonColor'),
                            confirmButtonText: this.$root.swal('closeButtonText'),
                        })
                    }
                })
            },
            login(value){
                let data = {
                    email: value.email,
                    password: value.uid,
                }
                this.$store.dispatch('auth/login', data).then((response) => {
                    if(response.status){
                        Swal.fire({
                            icon: 'success',
                            text: 'Login Berhasil',
                            confirmButtonColor: this.$root.swal('confirmButtonColor'),
                            confirmButtonText: this.$root.swal('doneButtonText'),
                        })
                        this.close_modal()
                    }
                    else{
                        this.$store.dispatch('auth/logout')
                    }
                })
            },
        }
	}
</script>