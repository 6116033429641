<template>
    <div class="py-5 px-3 px-lg-5 custom-default-bg" v-if="detail">
        <div class="row">
            <div class="col-12 mb-4">
                <div class="row justify-content-lg-between align-items-start pb-1 border-bottom">
                    <div class="col-12 col-lg-2 order-lg-2 mb-4 my-lg-2">
                        <router-link :to="{name: 'home'}" class="btn custom-btn-02 rounded-pill w-100">
                            <i class="fas fa-arrow-left me-2"></i>
                            Kembali
                        </router-link>
                    </div>
                    <div class="col-12 col-lg-10 order-lg-1 mb-2">
                        <h6 class="display-6 custom-font-color-02">{{ detail.name }}</h6>
                        <p class="h6 text-muted">{{ detail.code }}</p>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row gy-3">
                    <div class="col-12 col-lg-3">
                        <div class="d-flex justify-content-center">
                            <div class="custom-image-warpper shadow-sm" v-viewer="{toolbar: false, keyboard: false}">
                                <img :src="detail.logo_url" alt="health-center-logo" class="rounded">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-9">
                        <div class="custom-image-warpper shadow-sm rounded" v-viewer="{toolbar: false, keyboard: false}">
                            <img :src="detail.image_url" alt="info-image">
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <div class="custom-font-lg border-bottom my-3">Informasi</div>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <div class="custom-text-group">
                                    <label>Provinsi</label>
                                    <p>{{ detail.province_name }}</p>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="custom-text-group">
                                    <label>Kabupaten</label>
                                    <p>{{ detail.regency_name }}</p>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="custom-text-group">
                                    <label>Kecamatan</label>
                                    <p>{{ detail.district_name }}</p>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="custom-text-group">
                                    <label>Alamat</label>
                                    <p>{{ detail.address }}</p>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="custom-text-group">
                                    <label>Telepon</label>
                                    <p>{{ detail.phone }}</p>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="custom-text-group">
                                    <label>Informasi</label>
                                    <p class="custom-textarea-group">{{ detail.information }}</p>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <google-maps-component :center="detail.lat && detail.long ? {lat: detail.lat, lng: detail.long} : {lat: -6.1754, lng: 106.8272}" :size="{width: '100%', height: '300px'}"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-9">
                        <div class="row">
                            <div class="col-12" v-if="detail.polyclinic">
                                <div class="custom-font-lg border-bottom my-3">Poli Klinik</div>
                                <template v-if="user">
                                    <div class="alert alert-error text-center mb-3" v-if="!user.profile">
                                        Silahkan lengkapi profil anda terlebih dahulu untuk melakukan daftar antrian
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="alert alert-error text-center mb-3">
                                        Silahkan masuk terlebih dahulu untuk melakukan pendaftar antrian
                                    </div>
                                </template>
                                <div class="custom-table-wrapper">
                                    <table class="table table-hover custom-table align-middle" style="min-width: 760px;">
                                        <thead>
                                            <tr>
                                                <th style="width: 25%">Nama Poli</th>
                                                <th class="text-center" style="width: 15%">Kuota</th>
                                                <th class="text-center" style="width: 15%">Antri</th>
                                                <th class="text-center" style="width: 15%">Menunggu</th>
                                                <th class="text-center" style="width: 15%">Jumlah</th>
                                                <th style="width: 15%"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(polyclinic, index) in detail.polyclinic" :key="index">
                                                <td>{{ polyclinic.nama }}</td>
                                                <td class="text-center">{{ polyclinic.kuota ? polyclinic.kuota : 0 }}</td>
                                                <td class="text-center">{{ polyclinic.antri ? polyclinic.antri : 0 }}</td>
                                                <td class="text-center">{{ polyclinic.menunggu ? polyclinic.menunggu : 0 }}</td>
                                                <td class="text-center">{{ polyclinic.jumlah ? polyclinic.jumlah : 0 }}</td>
                                                <td>
                                                    <button type="button" class="btn btn-sm custom-btn-02 rounded-pill w-100" :disabled="!user.profile" v-if="user" @click="open_polyclinic_queue_modal(polyclinic.idpoli, polyclinic.nama)">Daftar</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-12 text-center" v-else>
                                <div class="h5 text-muted">No Data.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
	<polyclinic-queue-modal ref="polyclinicQueueModal"/>
</template>

<script>
    import GoogleMapsComponent from '@/components/external/GoogleMapsComponent'
    import PolyclinicQueueModal from '@/components/public/PolyclinicQueueModal'
    import { mapGetters } from 'vuex'

	export default {
        components: {
            'google-maps-component': GoogleMapsComponent,
            'polyclinic-queue-modal': PolyclinicQueueModal,
        },
        data() {
            return {
                detail: null,
            }
        },
        computed: {
            ...mapGetters({
                user: 'auth/getUser',
            }),
        },
        created(){
            this.init()
        },
        methods: {
            init(){
                this.$store.dispatch('health_center/detailHealthCenter', {health_center_id: this.$route.params.id}).then((response) => {
                    if(response.status){
                        this.detail = response.data[0]
                        this.detail.polyclinic = []

                        this.$store.dispatch('health_center/getPolyclinic', {health_center_code: this.detail.code}).then((response) => {
                            if(response.data && response.data[0].poli){
                                for(let i = 0; i < response.data[0].poli.length; i++){
                                    if(!['66', '67'].includes(response.data[0].poli[i].idpoli)){
                                        this.detail.polyclinic.push(response.data[0].poli[i])
                                    }
                                }
                            }
                            this.$store.dispatch('health_center/getPolyclinicList', {health_center_code: this.detail.code}).then((response) => {
                                if(response.data && response.data[0].poli){
                                    for(let i = 0; i < response.data[0].poli.length; i++){
                                        if(!this.detail.polyclinic.some(item => item.idpoli == response.data[0].poli[i].idpoli)){
                                            let poli = {
                                                idpoli: response.data[0].poli[i].idpoli,
                                                nama: response.data[0].poli[i].Nama,
                                                buka: response.data[0].poli[i].Buka,
                                                jumlah: "0",
                                                kuota: response.data[0].poli[i].Kuota,
                                                menunggu: "0",
                                            }

                                            this.detail.polyclinic.push(poli)
                                        }
                                    }
                                }
                            })
                        })
                    }
                })
            },
            open_polyclinic_queue_modal(id, name){
                let data = {
                    health_center_code: this.detail.code,
                    health_center_name: this.detail.name,
                    polyclinic_id: id,
                    polyclinic_name: name,
                }

                this.$refs.polyclinicQueueModal.open_modal(data)
            },
		}
	}
</script>