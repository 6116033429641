import Axios from 'axios'
import crypto from 'crypto-js'
import { constants } from '@/config/constant'
import { firebase } from '@/config/firebase'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth'

const checkAuth = async (context) => {
    const hasAuth = firebase.currentUser
    const hasToken = localStorage.getItem('token')
    
    if(hasAuth && hasToken){
        context.commit('SET_AUTH', hasAuth)
        context.commit('SET_USER', JSON.parse(crypto.AES.decrypt(localStorage.getItem('user'), constants.crypto_key).toString(crypto.enc.Utf8)))
    
        return true
    }
    else{
        await signOut(firebase)

        localStorage.removeItem('token')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('user')

        context.commit('RESET_USER')
        context.commit('RESET_AUTH')

        return false
    }
}

const signInEmail = async (context, payload) => {
    let response = {}

    try{
        await signInWithEmailAndPassword(firebase, payload.email, payload.password)

        // if(firebase.currentUser.emailVerified){
            context.commit('SET_AUTH', firebase.currentUser)

            response.status = true
            response.data = firebase.currentUser
        // }
        // else{
        //     await signOut(firebase)

        //     response.status = false
        //     response.message = 'Please check your email to verify your account.'
        // }

        return response
    }
    catch(error){
        response.status = false

        switch(error.code){
            case 'auth/user-not-found':
                response.message = 'User Not Found.'
                break
            case 'auth/wrong-password':
                response.message = 'Wrong Password.'
                break
            default:
                response.message = 'Oops, Something went wrong.'
        }
        
        return response
    }
}

const signInGoogle = async (context) => {
    let response = {}
    
    try{
        const provider = new GoogleAuthProvider()
        let result = await signInWithPopup(firebase, provider)

        context.commit('SET_AUTH', result.user)

        response.status = true
        response.data = result.user

        return response
    }
    catch(error){
        response.status = false

        switch(error.code){
            case 'auth/email-already-in-use':
                response.message = 'Email already in use.'
                break
            default:
                response.message = 'Oops, Something went wrong.'
        }

        return response
    }
}

const signUpEmail = async (context, payload) => {
    let response = {}

    try{
        await createUserWithEmailAndPassword(firebase, payload.email, payload.password)
        
        // await sendEmailVerification(firebase.currentUser)

        response.status = true
        response.data = firebase.currentUser

        // await signOut(firebase)

        return response
    }
    catch(error){
        response.status = false

        switch(error.code){
            case 'auth/email-already-in-use':
                response.message = 'Email already in use.'
                break
            case 'auth/invalid-email':
                response.message = 'Invalid email.'
                break
            case 'auth/operation-not-allowed':
                response.message = 'Operation not allowed.'
                break
            case 'auth/weak-password':
                response.message = 'Weak Password.'
                break
            default:
                response.message = 'Oops, Something went wrong.'
        }
        
        return response
    }
}

const register = async (context, payload) => {
    const url = constants.base_url + '/web/register'
    let response = await Axios.post(url, payload)
    
    return response.data
}

const login = async (context, payload) => {
    const url = constants.base_url + '/login'
    let response = await Axios.post(url, payload)

    if(response.data.status){
        localStorage.setItem('token', crypto.AES.encrypt(response.data.data.access_token, constants.crypto_key).toString())
        localStorage.setItem('refresh_token', crypto.AES.encrypt(response.data.data.refresh_token, constants.crypto_key).toString())
        localStorage.setItem('user', crypto.AES.encrypt(JSON.stringify(response.data.data.user), constants.crypto_key).toString())
        context.commit('SET_USER', JSON.parse(crypto.AES.decrypt(localStorage.getItem('user'), constants.crypto_key).toString(crypto.enc.Utf8)))
    }
    
    return response.data
}

const logout = async (context) => {
    const url = constants.base_url + '/logout'
    await Axios.get(url)

    await signOut(firebase)

    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('user')

    context.commit('RESET_USER')
    context.commit('RESET_AUTH')
}

const getProfile = async (context, payload) => {
    const url = constants.base_url + '/user/detail/' + payload
    let response = await Axios.get(url)

    return response.data
}

const saveProfile = async (context, payload) => {
    const url = constants.base_url + '/web/profile/save'
    let response = await Axios.post(url, payload, {headers:{'Content-Type': 'multipart/form-data'}})
    
    if(response.data.status){
        localStorage.setItem('user', crypto.AES.encrypt(JSON.stringify(response.data.data), constants.crypto_key).toString())
        context.commit('SET_USER', JSON.parse(crypto.AES.decrypt(localStorage.getItem('user'), constants.crypto_key).toString(crypto.enc.Utf8)))
    }

    return response.data
}

const resetSignInForm = async (context) => {
	context.commit('RESET_SIGN_IN_FORM')
}

const resetSignUpForm = async (context) => {
	context.commit('RESET_SIGN_UP_FORM')
}

const resetProfileForm = async (context) => {
	context.commit('RESET_PROFILE_FORM')
}

const resetForgotPasswordForm = async (context) => {
	context.commit('RESET_FORGOT_PASSWORD_FORM')
}

export {
    checkAuth,
    signInEmail,
    signInGoogle,
    signUpEmail,
    register,
    login,
    logout,
    getProfile,
    saveProfile,
    resetSignInForm,
    resetSignUpForm,
    resetProfileForm,
    resetForgotPasswordForm,
}