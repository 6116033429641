const SET_AUTH = (state, payload) => {
	state.auth = payload
}

const SET_USER = (state, payload) => {
	state.user = payload
}

const RESET_AUTH = (state) => {
	state.auth = null
}

const RESET_USER = (state) => {
	state.user = null
}

const RESET_SIGN_IN_FORM = (state) => {
	state.sign_in_form = {
        email: null,
        password: null
    }
}

const RESET_SIGN_UP_FORM = (state) => {
	state.sign_up_form = {
        email: null,
        password: null,
    }
}

const RESET_PROFILE_FORM = (state) => {
	state.profile_form = {
        name: null,
        nik: null,
        phone: null,
        gender: null,
        birth_date: null,
        address: null,
        health_center_id: null,
        no_bpjs: null,
        photo: null,
    }
}

const RESET_FORGOT_PASSWORD_FORM = (state) => {
	state.forgot_password_form = {
        email: null
    }
}

export {
    SET_AUTH,
    SET_USER,
    RESET_AUTH,
    RESET_USER,
    RESET_SIGN_IN_FORM,
    RESET_SIGN_UP_FORM,
    RESET_PROFILE_FORM,
    RESET_FORGOT_PASSWORD_FORM,
}