import Axios from 'axios'
import { constants } from '@/config/constant'

const getArticleCategory = async (context) => {
    const url = constants.base_url + '/article_category/all'
    let response = await Axios.get(url)

    if(response.data.status){
        context.commit('SET_ARTICLE_CATEGORY_OPTIONS', response.data)
    }

    return response.data
}

const getArticle = async (context, payload) => {
    const url = constants.base_url + '/article/all'
    let response = await Axios.get(url, {params: payload})

    return response.data
}

const detailArticle = async (context, payload) => {
    const url = constants.base_url + '/article/detail/' + payload
    let response = await Axios.get(url)

    return response.data
}

export {
    getArticleCategory,
    getArticle,
    detailArticle,
}