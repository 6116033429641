const getConstants = () => {
	return {
        // base_url: 'http://localhost:8000/api',
        base_url: 'https://jq-dev-api.ktr.my.id/api',
		crypto_key: 'AIzaSyD2Piwd8TiiJk-rC0an1MhKqCuVNJ1l1RM',
		google_console_key: 'AIzaSyCZZiknmQYd__uqoQxXf-9DA3oEMcj28AU',
        firebase_config: {
            apiKey: "AIzaSyD2Piwd8TiiJk-rC0an1MhKqCuVNJ1l1RM",
            authDomain: "j-care-q.firebaseapp.com",
            projectId: "j-care-q",
            storageBucket: "j-care-q.appspot.com",
            messagingSenderId: "62738625139",
            appId: "1:62738625139:web:ee98ed061af4b5befd7006",
            measurementId: "G-8TF5Y7E2BH"
        },
	};
};

const constants = getConstants();

export { constants };