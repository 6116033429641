<template>
	<div class="custom-navbar-bg fixed-top">
		<div class="custom-content-wrapper mx-auto">
			<nav class="navbar navbar-expand-lg custom-navbar-bg px-3 py-2 py-lg-0" :class="notification ? '' : 'shadow'">
				<a class="navbar-brand custom-navbar-brand d-flex align-items-center" @click="navbar_navigate('home')">
					<img src="@/assets/image/logo.png" class="custom-navbar-logo me-2">
					PORTAL ANTRIAN PASIEN
				</a>
				<div class="d-flex">
					<button class="navbar-toggler custom-toggler p-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<i class="fas fa-bars"></i>
					</button>
				</div>
				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<div class="navbar-nav justify-content-lg-between align-items-lg-center w-100">
						<div class="d-lg-flex justify-content-lg-around align-items-lg-center px-lg-5 text-center">
							<a href="javascript:void(0)" class="nav-link custom-navbar-link mb-3 mb-lg-0" :class="navbar == 'home' ? 'active' : ''" @click="navbar_navigate('home')">Beranda</a>
							<a href="javascript:void(0)" class="nav-link custom-navbar-link mb-3 mb-lg-0" :class="navbar == 'article' ? 'active' : ''" @click="navbar_navigate('article')">Artikel</a>
							<!-- <router-link :to="{name: 'home'}" class="nav-link custom-navbar-link mb-3 mb-lg-0" :class="navbar == 'home' ? 'active' : ''">Beranda</router-link>
							<router-link :to="{name: 'article'}" class="nav-link custom-navbar-link mb-3 mb-lg-0" :class="navbar == 'article' ? 'active' : ''">Artikel</router-link> -->
						</div>
						<div class="mb-3 mb-lg-0" v-if="user">
							<div class="dropdown">
								<div class="d-none d-lg-inline-block me-2">{{ user.profile ? user.profile.name : user.email.split('@')[0] }}</div>
								<button class="btn custom-btn-02 custom-btn-user custom-width-sm-100" type="button" id="navbarUserDropdown" data-bs-toggle="dropdown" aria-expanded="false">
									<div class="d-inline-block d-lg-none me-2">{{ user.profile ? user.profile.name : user.email.split('@')[0] }}</div>
									<i class="far fa-user"></i>
								</button>
								<div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarUserDropdown">
									<router-link :to="{name: 'polyclinic.history'}" class="dropdown-item">Riwayat Antrian</router-link>
									<router-link :to="{name: 'profile'}" class="dropdown-item">Profil</router-link>
									<div class="dropdown-divider"></div>
									<button class="dropdown-item" @click="sign_out()">Logout</button>
								</div>
							</div>
						</div>
						<div class="mb-3 mb-lg-0" v-else>
							<button type="button" class="btn custom-btn-02 custom-btn-user custom-width-sm-100" style="height: 40px;" @click="open_signin_modal()">
								<i class="far fa-user"></i>
							</button>
						</div>
					</div>
				</div>
			</nav>
		</div>
	</div>
	<signin-modal ref="signinModal" @open_sign_up="open_signup_modal"/>
	<signup-modal ref="signupModal" @open_sign_in="open_signin_modal"/>
</template>

<script>
	import SignInModal from '../SignInModal.vue'
	import SignUpModal from '../SignUpModal.vue'
	import Swal from 'sweetalert2'

	export default {
		components: {
            'signin-modal': SignInModal,
			'signup-modal': SignUpModal,
        },
		props:['user', 'notification'],
		computed: {
			navbar(){
				return this.$route.meta.navbar
			}
		},
		methods: {
			navbar_navigate(data){
				this.$emitter.emit('set_cached_components', data)
				this.$router.push({name: data})
			},
			open_signin_modal(){
                this.$refs.signinModal.open_modal()
            },
			open_signup_modal(){
                this.$refs.signupModal.open_modal()
            },
			sign_out(){
				this.$store.dispatch('auth/logout')
				Swal.fire({
                    icon: 'success',
                    text: 'Logout Berhasil',
                    confirmButtonColor: this.$root.swal('confirmButtonColor'),
                    confirmButtonText: this.$root.swal('doneButtonText'),
                })
			}
		}
	}
</script>

<style scoped>
	.custom-navbar-link {
		padding: 0.5rem !important;
		border-bottom: 0.25rem solid transparent;
	}

	.custom-navbar-link.active {
		color: rgba(24, 154, 180, 1);
		border-bottom: 0.25rem solid rgba(24, 154, 180, 1);
	}

	.custom-btn-user {
		border-radius: 50px;
	}

	@media screen and (min-width: 992px) {
		.custom-navbar-link {
			padding: 1.25rem 1.5rem 0.75rem 1.5rem !important;
		}

		.custom-btn-user {
			border-radius: 50%;
		}
	}
</style>