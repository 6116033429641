<template>
    <carousel-component class="custom-carousel" :wrap-around="true" :autoplay="5000" v-if="banners.length">
        <carousel-slide v-for="(banner, index) in banners" :key="index">
            <div class="custom-carousel-item">
                <img :src="banner.image_url" :alt="banner.image">
            </div>
        </carousel-slide>
        <template #addons>
            <carousel-navigation />
            <carousel-pagination />
        </template>
    </carousel-component>
    <div class="custom-padding custom-default-bg">
        <div class="custom-header text-center mb-5">Antrian Puskesmas</div>
        <div class="card custom-card">
            <div class="card-header custom-card-header over"></div>
            <div class="card-body">
                <div class="row gy-3">
                    <div class="col-12 col-lg-4">
                        <div class="custom-input-group">
                            <v-select v-model="province" :options="province_options" placeholder="Provinsi" label="label" :reduce="province_options => province_options.id" class="custom-vselect">
                                <template #open-indicator="{attributes}">
                                    <button type="button" class="btn custom-btn-02" v-bind="attributes">
                                        <i class="fas fa-chevron-down"></i>
                                    </button>
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="custom-input-group">
                            <v-select v-model="regency" :options="regency_options" placeholder="Kabupaten" label="label" :reduce="regency_options => regency_options.id" :disabled="!province" class="custom-vselect">
                                <template #open-indicator="{attributes}">
                                    <button type="button" class="btn custom-btn-02" v-bind="attributes" :disabled="regency_options.length == 0">
                                        <i class="fas fa-chevron-down"></i>
                                    </button>
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-lg-3">
                        <div class="custom-input-group">
                            <v-select v-model="district" :options="district_options" placeholder="Kecamatan" label="label" :reduce="district_options => district_options.id" :disabled="!regency" class="custom-vselect">
                                <template #open-indicator="{attributes}">
                                    <button type="button" class="btn custom-btn-02" v-bind="attributes" :disabled="district_options.length == 0">
                                        <i class="fas fa-chevron-down"></i>
                                    </button>
                                </template>
                            </v-select>
                        </div>
                    </div> -->
                    <div class="col-12 col-lg-4">
                        <div class="custom-input-group">
                            <button type="button" class="btn custom-btn-02" @click="search" :disabled="!province || !regency">
                                <i class="fas fa-search me-2"></i>
                                Cari Puskesmas
                            </button>
                        </div>
                    </div>
                    <div class="col-12 mt-0" v-if="health_centers && health_centers.length">
                        <div class="border-bottom my-4"></div>
                        <div class="row g-3">
                            <div class="col-12 col-lg-3" v-for="(health_center, index1) in health_centers" :key="index1">
                                <div class="row no-gutters mx-0 border rounded">
                                    <div class="col-4 p-2">
                                        <div class="custom-thumbnail shadow-sm" style="max-width: 100px;" v-viewer="{toolbar: false, keyboard: false}">
                                            <img :src="health_center.image_url" alt="health-center-logo" class="rounded">
                                        </div>
                                        <!-- <div class="custom-font-sm custom-font-color-secondary mb-1">Nama</div>
                                        {{ health_center.name }} -->
                                    </div>
                                    <div class="col-8 p-2">
                                        <div class="custom-font-bold mb-1">{{ health_center.name }}</div>
                                        <div class="custom-font-sm custom-font-color-secondary">{{ health_center.address }}</div>
                                        <!-- <div class="custom-font-sm custom-font-color-secondary mb-1">Poli</div>
                                        <template v-if="health_center.polyclinic">
                                            <div class="d-inline-block rounded-pill mb-1 me-1 py-1 px-2 custom-badge-02 custom-font-sm" v-for="(polyclinic, index2) in health_center.polyclinic" :key="index2">
                                                {{ polyclinic.nama }}
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="custom-font-sm custom-font-color-secondary mb-1">No Data.</div>
                                        </template> -->
                                    </div>
                                    <div class="col-12 p-2">
                                        <router-link :to="{name: 'health_center.detail', params: {id: health_center.id}}" class="btn custom-btn-02 rounded-pill w-100">
                                            Detail Puskesmas
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-5 text-center" v-if="health_centers && !health_centers.length">
                        <div class="h5 text-muted">No Data.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-padding custom-bg-03" v-if="articles.length">
        <div class="custom-header text-center mb-5">Berita dan Artikel APM</div>
        <div class="row jusify-content-around gt-4">
            <div class="col-12 col-md-6 col-lg-3" v-for="(article, index) in articles" :key="index">
                <template v-if="index < 4">
                    <div class="card custom-card overflow-hidden shadow border-0">
                        <div class="card-header p-0 border-0" style="border-radius: 1rem;">
                            <div class="custom-thumbnail">
                                <img :src="article.image_url" :alt="article.image" style="border-radius: 1rem;">
                            </div>
                        </div>
                        <div class="card-body text-center">
                            <h5>{{ article.title }}</h5>
                            <p>{{ article.excerpt }}</p>
                        </div>
                        <div class="card-footer custom-default-bg border-0 text-center py-3">
                            <router-link :to="{name: 'article.detail', params: {id: article.id}}" class="btn custom-btn-02 rounded-pill px-5">
                                Baca Selengkapnya
                            </router-link>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="py-5 text-center">
            <router-link :to="{name: 'article'}" class="btn custom-btn-02 rounded-pill px-5">Lihat Semua</router-link>
        </div>
    </div>
</template>

<script>
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    import vSelect from 'vue-select'
    import { mapGetters } from 'vuex'

	export default {
        name: 'homeComponent',
        components: {
            'carousel-component': Carousel,
            'carousel-slide': Slide,
            'carousel-pagination': Pagination,
            'carousel-navigation': Navigation,
            'v-select': vSelect,
        },
        data() {
			return {
				banners: [],
                articles: [],
                province: null,
                regency: null,
                // district: null,
                health_centers: null,
                attributes: {
                    'ref': 'openIndicator',
                    'role': 'presentation',
                    'class': 'vs__open-indicator',
                }
			};
		},
        computed: {
            ...mapGetters({
                province_options: 'master_data/getProvinceOptions',
                regency_options: 'master_data/getRegencyOptions',
                // district_options: 'master_data/getDistrictOptions',
            }),
        },
        watch: {
            'province': function (newData) {
                this.regency = null
                // this.district = null

                if(newData){
                    this.$store.dispatch('master_data/getRegencyOptions', {province_id: this.province, is_client: this.province == 33 ? 0 : 1})
                }
                else{
                    this.$store.dispatch('master_data/resetRegencyOptions')
                    // this.$store.dispatch('master_data/resetDistrictOptions')
                }
            },
            // 'regency': function (newData) {
            //     this.district = null

            //     if(newData){
            //         this.$store.dispatch('master_data/getDistrictOptions', {regency_id: this.regency})
            //     }
            //     else{
            //         this.$store.dispatch('master_data/resetDistrictOptions')
            //     }
            // }
        },
		created(){
            this.init()
        },
        methods: {
            init(){
                this.$store.dispatch('master_data/getProvinceOptions', {is_client: 1})
                this.$store.dispatch('master_data/getBanner').then((response) => {
                    if(response.status){
                        this.banners = response.data
                    }
                })
                this.$store.dispatch('article/getArticle').then((response) => {
                    if(response.status){
                        this.articles = response.data
                    }
                })
            },
            search(){
                let data = {
                    // province_id: this.province,
                    regency_id: this.regency,
                    // district_id: this.district,
                    is_client: this.province == 33 ? 0 : 1,
                    sort_by: 'name',
                    sort_type: 'asc',
                }

                this.$store.dispatch('health_center/getHealthCenter', data).then((response) => {
                    if(response.status){
                        this.health_centers = response.data

                        // this.health_centers.forEach((element, index) => {
                        //     this.$store.dispatch('health_center/getPolyclinic', {health_center_code: element.code}).then((response) => {
                        //         if(response.data && response.data[0].poli.length){
                        //             this.health_centers[index].polyclinic = response.data[0].poli
                        //         }
                        //     })
                        // });
                    }
                })
            }
        }
	}
</script>

<style scoped>
    .custom-padding {
        padding: 3rem 1rem;
    }

    .custom-header {
        position: relative;
        color: rgba(52, 72, 119, 1);
        font-size: 2.5rem;
        font-weight: 600;
        text-align: center;
    }

    .custom-header::after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: calc(50% - 60px);
        width: 120px;
        height: 4px;
        border-radius: 4px;
        background-color: rgba(24, 154, 180, 1);
    }

    .custom-card-header {
        background-color: rgba(24, 154, 180, 1);
        border-top-left-radius: 1rem !important;
        border-top-right-radius: 1rem !important;
    }

    .custom-input-group {
        position: relative;
        width: 100%;
        height: 48px;
        background-color: transparent;
    }

    .custom-input-group > button {
        width: 100%;
        height: 48px;
        border-radius: 48px;
    }

    :deep(.custom-input-group .custom-vselect .vs__dropdown-toggle) {
        height: 48px;
        border-radius: 48px;
    }

    :deep(.custom-input-group .custom-vselect .vs__actions) {
        padding-top: 0;
    }

    :deep(.custom-input-group .custom-vselect .vs__open-indicator) {
        width: 36px;
        height: 36px;
        padding: 0;
        border-radius: 36px;
    }

    :deep(.custom-input-group .custom-vselect .vs__selected-options) {
        white-space: nowrap;
    }

    :deep(.custom-input-group .custom-vselect .vs__selected) {
        min-height: 34px;
    }

	@media screen and (min-width: 992px) {
        .custom-padding {
            padding: 6rem 1rem;
        }
	}
</style>