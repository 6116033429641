const getDefaultState = () => {
	return {
		health_center_options: [],
		polyclinic_queue_form: {
			patient_id: null,
			name: null,
			phone: null,
			health_center_code: null,
			polyclinic_id: null,
			polyclinic_name: null,
			date: null,
		}
	}
}

const default_state = getDefaultState()

export { default_state }