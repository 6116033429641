<template>
	<!-- loading -->
	<div class="custom-loading" v-if="loading">
        <i class="fas fa-circle-notch fa-spin fa-5x"></i>
    </div>
	<!-- Content wrapper -->
	<div class="container-fluid p-0">
		<navbar-component :user="user" :notification="notification"></navbar-component>
		<notification-component :user="user" @notification_status="notification_status"></notification-component>
		<div class="custom-content-wrapper mx-auto" :style="notification ? 'padding-top: 92px;' : 'padding-top: 60px;'">
			<slot></slot>
			<footer-component></footer-component>
		</div>
	</div>
</template>

<script>
	import Navbar from './NavbarComponent'
	import Notification from './NotificationComponent'
    import Footer from './FooterComponent'
	import { mapGetters } from 'vuex'

	export default {
		components: {
			'navbar-component': Navbar,
			'notification-component': Notification,
            'footer-component': Footer,
		},
		data() {
			return {
				notification: false
			}
		},
		computed: {
            ...mapGetters({
                user: 'auth/getUser',
				loading: 'utilities/isLoading',
            }),
        },
		created(){
            this.$store.dispatch('auth/checkAuth')
        },
		methods: {
			notification_status(value) {
				this.notification = value
			}
		}
	}
</script>