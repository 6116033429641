<template>
    <div class="custom-padding custom-default-bg">
        <div class="custom-header text-center mb-5">Riwayat Antrian</div>
        <div class="row mb-4">
            <div class="col-12">
                <div class="custom-text-wrapper">
                    <div class="table-responsive" v-if="histories.length">
                        <table class="table table-hover align-middle">
                            <thead>
                                <tr>
                                    <th style="width: 35%">Nama Puskesmas</th>
                                    <th style="width: 25%">Nama Poli</th>
                                    <th style="width: 20%">Tanggal Pendaftaran</th>
                                    <th style="width: 20%">Tanggal Kunjungan</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(history, index) in histories" :key="index">
                                    <td>{{ history.health_center_name }}</td>
                                    <td>{{ history.polyclinic_name }}</td>
                                    <td>{{ date_format(history.registration_date) }}</td>
                                    <td>{{ date_format(history.visit_date) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center" v-else>
                        <div class="h5 text-muted">No Data.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-end">
            <div class="col-12 col-lg-3">
                <router-link :to="{name: 'home'}" class="btn custom-btn-outline-02 w-100">Kembali</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

	export default {
        data() {
            return {
                histories: [],
            }
        },
		created(){
            this.init()
        },
        methods: {
            init(){
                this.$store.dispatch('health_center/getPolyclinicHistory').then((response) => {
                    if(response.status){
                        this.histories = response.data
                    }
                })
            },
            date_format(value){
                return moment(value).format("DD-MM-YYYY hh:mm:ss")
            },
        }
	}
</script>

<style scoped>
    .custom-padding {
        padding: 3rem 1rem;
    }

    .custom-header {
        position: relative;
        color: rgba(52, 72, 119, 1);
        font-size: 2.5rem;
        font-weight: 600;
        text-align: center;
    }

    .custom-header::after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: calc(50% - 60px);
        width: 120px;
        height: 4px;
        border-radius: 4px;
        background-color: rgba(24, 154, 180, 1);
    }

    @media screen and (min-width: 992px) {
        .custom-padding {
            padding: 6rem 1rem;
        }
	}
</style>