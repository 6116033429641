const getHealthCenterOptions = (state) => {
    return state.health_center_options
}

const getPolyclinicQueueForm = (state) => {
    return state.polyclinic_queue_form
}

export {
    getHealthCenterOptions,
    getPolyclinicQueueForm,
};