<template>
    <div class="py-5 px-3 px-lg-5 custom-default-bg">
        <div class="custom-header text-center mb-5">Berita dan Artikel APM</div>
        <div class="row justify-content-lg-between mb-5 gy-3">
			<div class="col-12 col-lg-4 mb-3">
                <div class="custom-input-group">
                    <input type="text" class="form-control custom-form-control" placeholder="Cari Artikel" v-model="search">
                    <button type="button" class="btn custom-btn-02" @click="article_filter">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
			</div>
            <div class="col-12 col-lg-4">
                <div class="custom-input-group">
                    <v-select v-model="category_id" :options="article_category_options" placeholder="Pilih Kategori" label="label" :reduce="article_category_options => article_category_options.id" class="custom-vselect">
                        <template #open-indicator="{attributes}">
                            <button type="button" class="btn custom-btn-02" v-bind="attributes">
                                <i class="fas fa-chevron-down"></i>
                            </button>
                        </template>
                    </v-select>
                </div>
            </div>
        </div>
        <template v-if="articles.length">
            <div class="row jusify-content-around gy-4">
                <div class="col-12 col-md-6 col-lg-4" v-for="(article, index) in articles" :key="index">
                    <div class="card custom-card overflow-hidden shadow border-0">
                        <div class="card-header p-0 border-0" style="border-radius: 1rem;">
                            <div class="custom-thumbnail">
                                <img :src="article.image_url" :alt="article.image" style="border-radius: 1rem;">
                            </div>
                        </div>
                        <div class="card-body text-center">
                            <h5>{{ article.title }}</h5>
                            <p>{{ article.excerpt }}</p>
                        </div>
                        <div class="card-footer custom-default-bg border-0 text-center py-3">
                            <router-link :to="{name: 'article.detail', params: {id: article.id}}" class="btn custom-btn-02 rounded-pill px-5">
                                Baca Selengkapnya
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="text-center" v-else>
            <div class="h5 text-muted">No Data.</div>
        </div>
    </div>
</template>

<script>
    import vSelect from 'vue-select'
    import { mapGetters } from 'vuex'

	export default {
        name: 'articleComponent',
        components: {
            'v-select': vSelect,
        },
        data() {
			return {
                articles:[],
                search: null,
                sort_by: 'date',
                sort_type: 'desc',
                category_id: null,
                attributes: {
                    'ref': 'openIndicator',
                    'role': 'presentation',
                    'class': 'vs__open-indicator',
                }
			};
		},
        computed: {
            ...mapGetters({
                article_category_options: 'article/getArticleCategoryOptions',
            }),
        },
        watch: {
            'category_id': function () {
                this.article_filter()
            },
        },
		created(){
            this.init()
        },
        methods: {
            init(){
                this.$store.dispatch('article/getArticleCategory')
                this.$store.dispatch('article/getArticle', {sort_by: this.sort_by, sort_type: this.sort_type}).then((response) => {
                    if(response.status){
                        this.articles = response.data
                    }
                })
            },
            article_filter(){
                this.$store.dispatch('article/getArticle', {search: this.search, sort_by: this.sort_by, sort_type: this.sort_type, category_id: this.category_id}).then((response) => {
                    if(response.status){
                        this.articles = response.data
                    }
                })
            },
        }
	}
</script>

<style scoped>
    .custom-header {
        position: relative;
        color: rgba(52, 72, 119, 1);
        font-size: 2.5rem;
        font-weight: 600;
        text-align: center;
    }

    .custom-header::after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: calc(50% - 60px);
        width: 120px;
        height: 4px;
        border-radius: 4px;
        background-color: rgba(24, 154, 180, 1);
    }

    .custom-input-group {
        position: relative;
        width: 100%;
        height: 48px;
        background-color: transparent;
    }

    .custom-input-group > input {
        height: 100%;
        border-radius: 48px;
        padding-right: 48px;
    }

    .custom-input-group > button {
        position: absolute;
        right: 8px;
        top: 6px;
        width: 36px;
        height: 36px;
        padding: 0;
        border-radius: 36px;
    }

    :deep(.custom-input-group .custom-vselect .vs__dropdown-toggle) {
        height: 48px;
        border-radius: 48px;
    }

    :deep(.custom-input-group .custom-vselect .vs__actions) {
        padding-top: 0;
    }
    
    :deep(.custom-input-group .custom-vselect .vs__open-indicator) {
        width: 36px;
        height: 36px;
        padding: 0;
        border-radius: 36px;
    }
</style>