<template>
    <div class="custom-footer-bg">
        <div class="row">
            <div class="col-12 col-lg-5 mb-5 mb-lg-4">
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <div class="text-center mb-4">
                            <img src="@/assets/image/logo.png" style="width: 65px; height: auto;">
                        </div>
                    </div>
                    <div class="col-12 col-lg-9">
                        <h4 class="mb-4">Alamat</h4>
                        <div class="d-flex align-items-start mb-3">
                            <div class="text-center pe-3 pt-1" style="width: 30px; height: 30px;">
                                <i class="fas fa-map-marker-alt fa-lg"></i>
                            </div>
                            <p>Gg. Elang No.162B, Maguwoharjo, Sleman, Yogyakarta.</p>
                        </div>
                        <div class="d-flex align-items-start mb-3">
                            <div class="text-center pe-3 pt-1" style="width: 30px; height: 30px;">
                                <i class="far fa-clock fa-lg"></i>
                            </div>
                            <p>Senin - Sabtu<br>Pukul: 08:00 - 16:00</p>
                        </div>
                        <div class="d-flex align-items-start mb-3">
                            <div class="text-center pe-3 pt-1" style="width: 30px; height: 30px;">
                                <i class="fas fa-phone fa-lg"></i>
                            </div>
                            <p>(0274) 4462500</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 mb-5 mb-lg-4">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <h4 class="mb-4">Produk</h4>
                        <div class="mb-3">
                            <a href="#">SIMPUS</a>
                        </div>
                        <div class="mb-3">
                            <a href="#">APM</a>
                        </div>
                        <div class="mb-3">
                            <a href="#">GISKIA</a>
                        </div>
                        <div class="mb-3">
                            <a href="#">SIMPEDU</a>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <h4 class="mb-4">Bantuan</h4>
                        <div class="mb-3">
                            <a href="#">Hubungi Kami</a>
                        </div>
                        <div class="mb-3">
                            <a href="#">FAQ</a>
                        </div>
                        <div class="mb-3">
                            <a href="#">Syarat dan Kententuan</a>
                        </div>
                        <div class="mb-3">
                            <a href="#">Kebijakan Privasi</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-3 mb-5 mb-lg-4">
                <h4 class="mb-4">Sosial Media</h4>
                <div class="d-flex justify-content-between">
                    <div class="custom-footer-link d-flex justify-content-center align-items-center rounded-circle">
                        <a href="#"><i class="fab fa-whatsapp fa-2x"></i></a>
                    </div>
                    <div class="custom-footer-link d-flex justify-content-center align-items-center rounded-circle">
                        <a href="#"><i class="fab fa-facebook-f fa-2x"></i></a>
                    </div>
                    <div class="custom-footer-link d-flex justify-content-center align-items-center rounded-circle">
                        <a href="#"><i class="fab fa-instagram fa-2x"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center">
                Kinaryatama Raharja © {{ current_year }}.
            </div>
        </div>
    </div>
</template>

<script>
	import moment from 'moment';

	export default {
		data() {
			return {
				current_year: moment().year(),
			}
		},
	}
</script>

<style scoped>
    .custom-footer-bg {
        position: relative;
        padding: 3rem;
        background-color: rgba(24, 154, 180, 1);
        color: rgba(255, 255, 255, 1);
    }

    .custom-footer-bg a {
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
    }

    .custom-footer-bg a:hover {
        color: rgba(52, 72, 119, 1);
        text-decoration: underline;
    }

    .custom-footer-link {
        width: 48px;
        height: 48px;
        background-color: rgba(255, 255, 255, 1);
    }

    .custom-footer-link:hover {
        background-color: rgba(52, 72, 119, 1);
    }
    .custom-footer-link  a {
        color: rgba(24, 154, 180, 1);
        text-decoration: none;
    }

    .custom-footer-link:hover a{
        color: rgba(255, 255, 255, 1);
        text-decoration: underline;
    }
</style>