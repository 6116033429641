<template>
    <div class="custom-padding custom-default-bg">
        <div class="custom-header text-center mb-5">Profil</div>
        <div class="row">
            <div class="col-12">
                <div class="custom-text-wrapper">
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <div class="col-12 mb-3" :class="validation.firstError('form.photo') ? 'custom-form-error' : ''">
                                <label>Foto</label>
                                <file-input
                                    :reference="'photo'"
                                    :multiple="true"
                                    :maximum="1"
                                    :max_size="2097152"
                                    :accept="'image/png, image/jpg, image/jpeg'"
                                    :extensions="['png', 'jpg', 'jpeg']"
                                    @set_queue="queue_files"
                                    :old_file="local_file"
                                    :old_delete="true"
                                />
                                <div class="custom-form-error-message">{{ validation.firstError('form.photo') }}</div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-8">
                            <div class="mb-3">
                                <label class="form-label">Email</label>
                                <input type="text" class="form-control custom-form-control" placeholder="Email" v-model="user.email" disabled>
                            </div>
                            <div class="mb-3" :class="validation.firstError('form.name') ? 'custom-form-error' : ''">
                                <label class="form-label">Nama</label>
                                <input type="text" class="form-control custom-form-control" placeholder="Nama" v-model="form.name">
                                <div class="custom-form-error-message">{{ validation.firstError('form.name') }}</div>
                            </div>
                            <div class="mb-3" :class="validation.firstError('form.nik') ? 'custom-form-error' : ''">
                                <label class="form-label">NIK</label>
                                <input type="text" class="form-control custom-form-control" placeholder="No. NIK" v-model="form.nik">
                                <div class="custom-form-error-message">{{ validation.firstError('form.nik') }}</div>
                            </div>
                            <div class="mb-3" :class="validation.firstError('form.phone') ? 'custom-form-error' : ''">
                                <label class="form-label">Telepon</label>
                                <input type="text" class="form-control custom-form-control" placeholder="Telepon" v-model="form.phone">
                                <div class="custom-form-error-message">{{ validation.firstError('form.phone') }}</div>
                            </div>
                            <div class="mb-3" :class="validation.firstError('form.birth_date') ? 'custom-form-error' : ''">
                                <label class="form-label">Tanggal Lahir</label>
                                <date-input placeholder="Tanggal Lahir" :initial_value="form.birth_date" :max_date="new Date()" :clear="false" @change="set_birth_date"/>
                                <div class="custom-form-error-message">{{ validation.firstError('form.birth_date') }}</div>
                            </div>
                            <div class="mb-3" :class="validation.firstError('form.gender') ? 'custom-form-error' : ''">
                                <label class="form-label">Jenis Kelamin</label>
                                <div class="d-flex">
                                    <div class="custom-radio me-3">
                                        <input type="radio" id="male" value="pria" v-model="form.gender">
                                        <label for="male">Pria</label>
                                    </div>
                                    <div class="custom-radio ms-3">
                                        <input type="radio" id="female" value="wanita" v-model="form.gender">
                                        <label for="female">Wanita</label>
                                    </div>
                                </div>
                                <div class="custom-form-error-message">{{ validation.firstError('form.gender') }}</div>
                            </div>
                            <div class="mb-3" :class="validation.firstError('form.address') ? 'custom-form-error' : ''">
                                <label class="form-label">Alamat</label>
                                <textarea class="form-control custom-form-control custom-textarea" placeholder="Alamat" v-model="form.address" rows="3"/>
                                <div class="custom-form-error-message">{{ validation.firstError('form.address') }}</div>
                            </div>
                            <div class="mb-3" :class="validation.firstError('form.health_center_id') ? 'custom-form-error' : ''">
                                <label class="form-label">Puskesmas</label>
                                <v-select v-model="form.health_center_id" :options="health_center_options" placeholder="Puskesmas" label="label" :reduce="health_center_options => health_center_options.id" class="custom-vselect"/>
                                <div class="custom-form-error-message">{{ validation.firstError('form.health_center_id') }}</div>
                            </div>
                            <div class="mb-3" :class="validation.firstError('form.no_bpjs') ? 'custom-form-error' : ''">
                                <label class="form-label">No. BPJS</label>
                                <input type="text" class="form-control custom-form-control" placeholder="No. BPJS" v-model="form.no_bpjs">
                                <div class="custom-form-error-message">{{ validation.firstError('form.no_bpjs') }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-12 col-lg-3 order-lg-2">
                            <button type="button" class="btn custom-btn-02 w-100" @click="submit()">Simpan</button>
                        </div>
                        <div class="col-12 col-lg-3 order-lg-1">
                            <router-link :to="{name: 'home'}" class="btn custom-btn-outline-02 w-100">Kembali</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FileInput from '@/components/external/input/FileInput'
    import DateInput from '@/components/external/input/date/DateInput.vue'
    import vSelect from 'vue-select'
    import { mapGetters } from 'vuex'
    import { Validator } from 'simple-vue3-validator'
    import Swal from 'sweetalert2'
    import moment from 'moment'

	export default {
        components: {
            'file-input': FileInput,
            'date-input': DateInput,
            'v-select': vSelect,
        },
        data() {
            return {
                local_file: [],
            }
        },
        computed: {
            ...mapGetters({
                user: 'auth/getUser',
                form: 'auth/getProfileForm',
                health_center_options: 'health_center/getHealthCenterOptions',
            }),
        },
        validators: {
            'form.name': function(value){
                return Validator.value(value).required('Wajib di isi.')
            },
            'form.nik': function(value){
                return Validator.value(value).required('Wajib di isi.').integer('Hanya angka.')
            },
            'form.phone': function(value){
                return Validator.value(value).required('Wajib di isi.').integer('Hanya angka.').minLength(10, 'Minimal 10 Digit.').maxLength(14, 'Maksimal 14 Digit')
            },
            'form.birth_date': function(value){
                return Validator.value(value).required('Wajib di isi.')
            },
            'form.gender': function(value){
                return Validator.value(value).required('Wajib di pilih.')
            },
            'form.address': function(value){
                return Validator.value(value).required('Wajib di isi.')
            },
            'form.health_center_id': function(value){
                return Validator.value(value).required('Wajib di pilih.')
            },
            'form.no_bpjs': function(value){
                return Validator.value(value).integer('Hanya angka.')
            },
            'form.photo': function(value){
                return Validator.custom(function () {
                    if (!Validator.isEmpty(value)) {
                        for(var i = 0; i < value.length; i++) {
                            if (value[i].error != '') {
                                return 'Invalid file format.'
                            }
                        }
                    }
                })
            },
        },
		created(){
            this.$store.dispatch('auth/resetProfileForm')
            this.$store.dispatch('health_center/getHealthCenter', {is_client: 1})
            this.init()
        },
        methods: {
            init(){
                this.local_file = []
                this.$store.dispatch('auth/getProfile', this.user.id).then((response) => {
                    if(response.status){
                        this.form.name = response.data.profile ? response.data.profile.name : null
                        this.form.gender = response.data.profile ? response.data.profile.gender : null
                        this.form.birth_date = response.data.profile ? response.data.profile.birth_date : null
                        this.form.address = response.data.profile ? response.data.profile.address : null
                        this.form.phone = response.data.phone ? response.data.phone : null
                        this.form.nik = response.data.profile ? response.data.profile.nik : null
                        this.form.health_center_id = response.data.profile ? response.data.profile.health_center_id : null
                        this.form.no_bpjs = response.data.profile ? response.data.profile.no_bpjs : null

                        if(response.data.profile && response.data.profile.photo_url){
                            this.local_file.push({
                                error: '',
                                file_status: this.$root.copy(response.data.profile.photo),
                                file_name: this.$root.copy(response.data.profile.photo),
                                file_url: this.$root.copy(response.data.profile.photo_url),
                            })
                        }
                    }
                })
            },
            set_birth_date(value){
                this.form.birth_date = moment(value).valueOf()
            },
            queue_files(data){
                if(data.files.length > 0){
                    let files = []
                    for (let i = 0; i < data.files.length; ++i) {
                        if(data.files[i].file){
                            files.push({
                                error: data.files[i].error,
                                file: new File([data.files[i].file], data.files[i].file.name, {type: data.files[i].file.type})
                            })
                        }
                        else{
                            files.push({
                                error: data.files[i].error,
                                file_status: data.files[i].file_status,
                                file_name: data.files[i].file_name,
                                file_url: data.files[i].file_url
                            })
                        }
                    }
                    this.form[data.ref] = files
                }
                else{
                    this.form[data.ref] = null
                }
            },
            submit(){
                let parent = this
                parent.$validate().then(function (success){
                    if(success){
                        let formData = new FormData();
                        formData.append('name', parent.form.name)
                        formData.append('nik', parent.form.nik)
                        formData.append('phone', parent.form.phone)
                        formData.append('gender', parent.form.gender)
                        formData.append('birth_date', parent.form.birth_date)
                        formData.append('address', parent.form.address)
                        formData.append('health_center_id', parent.form.health_center_id)

                        if(parent.form.no_bpjs){
                            formData.append('no_bpjs', parent.form.no_bpjs)
                        }

                        if(parent.form.photo){
                            for(let i= 0; i < parent.form.photo.length; i++) {
                                if(parent.form.photo[i].file){
                                    formData.append("photo", parent.form.photo[i].file, parent.form.photo[i].file.name)
                                }
                                else{
                                    formData.append('photo', parent.form.photo[i].file_status)
                                }
                            }
                        }

                        parent.$store.dispatch('auth/saveProfile', formData).then((response) => {
                            if(response.status){
                                parent.init()
                                Swal.fire({
                                    icon: 'success',
                                    text: 'Profil Tersimpan',
                                    confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                    confirmButtonText: parent.$root.swal('doneButtonText'),
                                })
                            }
                        })
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: parent.$root.swal('errorValidationText'),
                            confirmButtonColor: parent.$root.swal('errorButtonColor'),
                            confirmButtonText: parent.$root.swal('closeButtonText'),
                        })
                    }
                })
            }
        }
	}
</script>

<style scoped>
    .custom-padding {
        padding: 3rem 1rem;
    }

    .custom-header {
        position: relative;
        color: rgba(52, 72, 119, 1);
        font-size: 2.5rem;
        font-weight: 600;
        text-align: center;
    }

    .custom-header::after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: calc(50% - 60px);
        width: 120px;
        height: 4px;
        border-radius: 4px;
        background-color: rgba(24, 154, 180, 1);
    }
    :deep(.custom-date-input .dp__input) {
        border-radius: 1rem;
    }

    @media screen and (min-width: 992px) {
        .custom-padding {
            padding: 6rem 1rem;
        }
	}
</style>