const SET_ARTICLE_CATEGORY_OPTIONS = (state, payload) => {
    state.article_category_options = []
    for (let i = 0; i < payload.data.length; ++i) {
        state.article_category_options.push({
            id: payload.data[i].id,
            label: payload.data[i].name
        })
    }
}

export {
    SET_ARTICLE_CATEGORY_OPTIONS,
}