const getAuth = (state) =>{
	return state.auth;
}

const getUser = (state) =>{
	return state.user;
}

const getSignInForm = (state) =>{
	return state.sign_in_form;
}

const getSignUpForm = (state) =>{
	return state.sign_up_form;
}

const getProfileForm = (state) =>{
	return state.profile_form;
}

const getFrogotPasswordForm = (state) =>{
	return state.forgot_password_form;
}

export {
    getAuth,
    getUser,
    getSignInForm,
    getSignUpForm,
    getProfileForm,
    getFrogotPasswordForm,
};