const SET_HEALTH_CENTER_OPTIONS = (state, payload) => {
    state.health_center_options = []
    for (let i = 0; i < payload.data.length; ++i) {
        state.health_center_options.push({
            id: payload.data[i].id,
            label: payload.data[i].name
        })
    }
}

const RESET_POLYCLINIC_QUEUE_FORM = (state) => {
    state.polyclinic_queue_form = {
        patient_id: null,
        name: null,
        phone: null,
        health_center_code: null,
        polyclinic_id: null,
        polyclinic_name: null,
        date: null,
    }
}

export {
    SET_HEALTH_CENTER_OPTIONS,
    RESET_POLYCLINIC_QUEUE_FORM,
}