import { createRouter, createWebHistory } from 'vue-router'

//Routes
import PublicRoutes from './public/routes';
import ErrorRoutes from './error/routes';

var allRoutes = [];

const routes = allRoutes.concat(PublicRoutes, ErrorRoutes)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router