import Home from "@/components/public/pages/HomeComponent";
import Profile from "@/components/public/pages/ProfileComponent";
import PolyclinicHistory from "@/components/public/pages/PolyClinicHistoryComponent";
import Articel from "@/components/public/pages/ArticleListComponent";
import ArticleDetail from "@/components/public/pages/ArticleDetailComponent";
import HealthCenterDetail from "@/components/public/pages/HealthCenterDetailComponent";

export default [
	{
		path : '/',
		name : 'home',
		meta : { layout: 'public', navbar: 'home', auth: false },
		component : Home
	},
	{
		path : '/profile',
		name : 'profile',
		meta : { layout: 'public', navbar: 'user', auth: true },
		component : Profile
	},
	{
		path : '/polyclinic/history',
		name : 'polyclinic.history',
		meta : { layout: 'public', navbar: 'user', auth: true },
		component : PolyclinicHistory
	},
	{
		path : '/article',
		name : 'article',
		meta : { layout: 'public', navbar: 'article', auth: false },
		component : Articel
	},
	{
		path : '/article/detail/:id',
		name : 'article.detail',
		meta : { layout: 'public', navbar: 'article', auth: false },
		component : ArticleDetail
	},
	{
		path : '/health_center/detail/:id',
		name : 'health_center.detail',
		meta : { layout: 'public', navbar: 'health_center', auth: false },
		component : HealthCenterDetail
	},
];