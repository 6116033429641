const getDefaultState = () => {
	return {
		auth: null,
		user: null,
		sign_in_form: {
			email: null,
			password: null
		},
		sign_up_form: {
			email: null,
			password: null,
		},
		profile_form: {
			name: null,
			nik: null,
			phone: null,
			gender: null,
			birth_date: null,
			address: null,
			health_center_id: null,
			no_bpjs: null,
			photo: null,
		},
		forgot_password_form: {
			email: null
		},
	}
}

const default_state = getDefaultState()

export { default_state }