<template>
	<modal-component :modal_status="modal">
		<template v-slot:modal_header>
			<div class="modal-title custom-font-lg custom-font-bold">Daftar Antrian</div>
			<button type="button" class="btn-close" @click="close_modal(false)"/>
		</template>
		<template v-slot:modal_body>
			<div class="row">
				<div class="col-12">
					<div class="mb-3" :class="validation.firstError('form.nik') ? 'custom-form-error' : ''">
						<label class="form-label">NIK</label>
						<input type="text" class="form-control custom-form-control" placeholder="No. NIK" v-model="form.patient_id">
						<div class="custom-form-error-message">{{ validation.firstError('form.nik') }}</div>
					</div>
					<div class="mb-3" :class="validation.firstError('form.name') ? 'custom-form-error' : ''">
						<label class="form-label">Nama</label>
						<input type="text" class="form-control custom-form-control" placeholder="Nama" v-model="form.name">
						<div class="custom-form-error-message">{{ validation.firstError('form.name') }}</div>
					</div>
					<div class="mb-3" :class="validation.firstError('form.phone') ? 'custom-form-error' : ''">
						<label class="form-label">Telepon</label>
						<input type="text" class="form-control custom-form-control" placeholder="Telepon" v-model="form.phone">
						<div class="custom-form-error-message">{{ validation.firstError('form.phone') }}</div>
					</div>
					<div class="mb-3">
						<label class="form-label">Puskesmas</label>
						<input type="text" class="form-control custom-form-control" placeholder="Puskesmas" v-model="health_center_name" disabled>
					</div>
					<div class="mb-3">
						<label class="form-label">Poli Klinik</label>
						<input type="text" class="form-control custom-form-control" placeholder="Poli Klinik" v-model="form.polyclinic_name" disabled>
					</div>
					<div class="mb-3" :class="validation.firstError('form.date') ? 'custom-form-error' : ''">
						<label class="form-label">Tanggal Kunjungan</label>
						<date-input placeholder="Tanggal Kunjungan" :min_date="new Date()" :clear="false" @change="set_date"/>
						<div class="custom-form-error-message">{{ validation.firstError('form.date') }}</div>
					</div>
				</div>
			</div>
		</template>
		<template v-slot:modal_footer>
			<button type="button" class="btn custom-btn-outline-02 rounded-pill" @click="close_modal(false)">Tutup</button>
			<button type="button" class="btn custom-btn-02 rounded-pill" @click="submit()">Daftar</button>
		</template>
	</modal-component>
</template>

<script>
	import ModalComponent from '@/components/external/ModalComponent'
	import DateInput from '@/components/external/input/date/DateInput.vue'
	import { mapGetters } from 'vuex'
	import { Validator } from 'simple-vue3-validator'
	import Swal from 'sweetalert2'
	import moment from 'moment'

	export default {
		components: {
			'modal-component': ModalComponent,
			'date-input': DateInput,
		},
		data() {
			return {
				modal: false,
				health_center_name: null,
			}
		},
		computed: {
			...mapGetters({
				user: 'auth/getUser',
				form: 'health_center/getPolyclinicQueueForm',
			}),
		},
		validators: {
			'form.patient_id': function(value){
				return Validator.value(value).required('Wajib di isi.').integer('Hanya angka.')
			},
			'form.name': function(value){
				return Validator.value(value).required('Wajib di isi.')
			},
			'form.phone': function(value){
				return Validator.value(value).required('Wajib di isi.').integer('Hanya angka.').minLength(10, 'Minimal 10 Digit.').maxLength(14, 'Maksimal 14 Digit')
			},
			'form.date': function(value){
                return Validator.value(value).required('Wajib di isi.')
            },
		},
		methods: {
			open_modal(value){
				this.form.patient_id = this.user.profile.nik
				this.form.name = this.user.profile.name
				this.form.phone = this.user.phone
				this.form.polyclinic_id = value.polyclinic_id
				this.form.polyclinic_name = value.polyclinic_name
				this.form.health_center_code = value.health_center_code
				this.health_center_name = value.health_center_name
				this.modal = true
			},
			close_modal(){
				this.modal = false
				this.$store.dispatch('health_center/resetPolyclinicQueueForm')
				this.validation.reset()
			},
			set_date(value){
                this.form.date = moment(value).format('YYYY-MM-DD')
            },
			submit(){
				let parent = this
                parent.$validate().then(function (success){
                    if(success){
						parent.$store.dispatch('health_center/createPolyclinicQueue', parent.form).then((response) => {
                            if(response.status){
                                parent.close_modal()
                                Swal.fire({
                                    icon: 'success',
                                    text: 'Berhasil mendaftar antrian.',
                                    confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                    confirmButtonText: parent.$root.swal('doneButtonText'),
                                })
                            }
                        })
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: parent.$root.swal('errorValidationText'),
                            confirmButtonColor: parent.$root.swal('errorButtonColor'),
                            confirmButtonText: parent.$root.swal('closeButtonText'),
                        })
                    }
                })
			},
		}
	}
</script>

<style scoped>
    :deep(.custom-date-input .dp__input) {
        border-radius: 1rem;
    }
</style>