<template>
    <div class="custom-banner-notification shadow" v-if="user">
        <div class="custom-font-md px-3 py-1" v-if="!user.profile">
            <span class="custom-font-color-default me-1">Silahkan lengkapi profil anda.</span>
            <router-link :to="{name: 'profile'}" class="custom-font-color-default custom-font-bold">Klik disini</router-link>
        </div>
    </div>
</template>

<script>
	export default {
		props:['user'],
		emits: ['notification_status'],
		mounted(){
			if(this.user){
				if(!this.user.profile){
					this.$emit('notification_status', true)
				}
				else{
					this.$emit('notification_status', false)
				}
			}
			else{
				this.$emit('notification_status', false)
			}
		},
		watch: {
			user: {
				deep: true,
				handler: function() {
					if(this.user){
						if(!this.user.profile){
							this.$emit('notification_status', true)
						}
						else{
							this.$emit('notification_status', false)
						}
					}
					else{
						this.$emit('notification_status', false)
					}
				}
			},
		},
	}
</script>

<style scoped>
    .custom-banner-notification {
		z-index: 1020;
		position: fixed;
		top: 60px;
        width: 100%;
        background-color: rgba(52, 72, 119, 1);
        text-align: center;
    }
</style>