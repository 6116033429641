import { createApp } from 'vue'
import { initialize } from './config/general'
import { copy, currency, swal } from './config/helper'
import { firebase } from '@/config/firebase'
import router from './router'
import store from './store'
import emitter from '@/config/emitter'
import SimpleVueValidator from 'simple-vue3-validator'
import infiniteScroll from 'vue3-infinite-scroll-good'
import VueViewer from 'v-viewer'
import 'bootstrap'
import '@popperjs/core'
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-select/dist/vue-select.css'
import 'viewerjs/dist/viewer.css'
import 'cropperjs/dist/cropper.css'
import 'vue3-carousel/dist/carousel.css'
import './assets/sass/app.scss'

//Main Application
import MainApp from './components/MainApp'

//Build
let app

firebase.onAuthStateChanged(() => {
    if(!app){
        app = createApp(MainApp)

        //Libraries Configuration
        initialize(router, store)

        app.use(router)
        app.use(store)
        app.use(emitter)
        app.use(SimpleVueValidator, {mode: 'conservative'})
        app.use(infiniteScroll)
        app.use(VueViewer)

        app.mixin({
            methods: {
                copy: copy,
                currency: currency,
                swal: swal,
            }
        })

        //Layouts
        app.component('public-layout', require('./components/public/layouts/PublicLayoutComponent.vue').default)
        app.component('error-layout', require('./components/error/ErrorLayoutComponent.vue').default)

        // Render
        app.mount('#app')
    }
})