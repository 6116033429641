import { createStore } from 'vuex'

//Modules
import auth from './auth';
import article from './article';
import health_center from './health_center';
import master_data from './master_data';
import utilities from './utilities';

export default createStore({
	modules: {
		auth,
		article,
		health_center,
		master_data,
		utilities,
	}
})