import Axios from 'axios'
import { constants } from '@/config/constant'

const getHealthCenter = async (context, payload) => {
    const url = constants.base_url + '/health_center/all'
    let response = await Axios.get(url, {params: payload})

    if(response.data.status){
        context.commit('SET_HEALTH_CENTER_OPTIONS', response.data)
    }

    return response.data
}

const detailHealthCenter = async (context, payload) => {
    const url = constants.base_url + '/health_center/all'
    let response = await Axios.get(url, {params: payload})

    return response.data
}

const getPolyclinic = async (context, payload) => {
    const url = constants.base_url + '/polyclinic/total_queue'
    let response = await Axios.get(url, {params: payload})

    return response.data
}

const getPolyclinicList = async (context, payload) => {
    const url = constants.base_url + '/polyclinic/list'
    let response = await Axios.get(url, {params: payload})

    return response.data
}

const getPolyclinicHistory = async (context, payload) => {
    const url = constants.base_url + '/polyclinic/queue'
    let response = await Axios.get(url, {params: payload})

    return response.data
}

const createPolyclinicQueue = async (context, payload) => {
    const url = constants.base_url + '/polyclinic/create_queue'
    let response = await Axios.post(url, payload)

    return response.data
}

const resetPolyclinicQueueForm = async (context) => {
	context.commit('RESET_POLYCLINIC_QUEUE_FORM')
}

export {
    getHealthCenter,
    detailHealthCenter,
    getPolyclinic,
    getPolyclinicList,
    getPolyclinicHistory,
    createPolyclinicQueue,
    resetPolyclinicQueueForm,
}