<template>
    <div class="py-5 px-3 px-lg-5 custom-default-bg" v-if="detail">
        <div class="row">
            <div class="col-12 mb-4">
                <div class="row justify-content-lg-between align-items-start pb-1 border-bottom">
                    <div class="col-12 col-lg-2 order-lg-2 mb-4 my-lg-2">
                        <router-link :to="{name: 'article'}" class="btn custom-btn-02 rounded-pill w-100">
                            <i class="fas fa-arrow-left me-2"></i>
                            Kembali
                        </router-link>
                    </div>
                    <div class="col-12 col-lg-10 order-lg-1 mb-2">
                        <h6 class="display-6 custom-font-color-02">{{ detail.title }}</h6>
                        <p class="h6 custom-font-color-01">{{ detail.user }}<span class="text-muted"> | {{ datetime_format(detail.date) }}</span></p>
                    </div>
                </div>
            </div>
            <div class="col-12 my-3" v-if="detail.image_url">
                <div class="custom-full-image-warpper shadow-sm rounded" v-viewer="{toolbar: false, keyboard: false}">
                    <img :src="detail.image_url" alt="info-image">
                </div>
            </div>
            <div class="col-12 mb-3 my-lg-4">
                <p class="lead text-center"><em>{{ detail.excerpt }}</em></p>
            </div>
            <div class="col-12 mb-3">
                <div class="custom-text-group">
                    <p class="custom-textarea-group">{{ detail.body }}</p>
                </div>
            </div>
            <div class="col-12 mt-4" v-if="detail.url">
                <div class="text-muted custom-font-sm">Kategori</div>
                <p class="custom-textarea-group">{{ detail.category_name }}</p>
            </div>
            <div class="col-12 mt-4" v-if="detail.url">
                <div class="text-muted custom-font-sm">Alamat Url Sumber</div>
                <a href="javascript:void(0)" @click="redirect_url(detail.url)">{{ detail.url }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

	export default {
        data() {
            return {
                detail: null,
            }
        },
        created(){
            this.init()
        },
        methods: {
            init(){
                this.$store.dispatch('article/detailArticle', this.$route.params.id).then((response) => {
                    if(response.status){
                        this.detail = response.data
                    }
                })
            },
            datetime_format(value){
                return moment(value).format("DD-MM-YYYY / HH:mm")
            },
            redirect_url(value){
                window.open('https://' + value, '_blank')
            }
		}
	}
</script>