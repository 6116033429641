<template>
	<component :is="layout">
		<router-view v-slot="{Component}">
			<keep-alive :include="cached_components">
				<component :is="Component"/>
			</keep-alive>
		</router-view>
	</component>
</template>

<script>
	export default {
		data() {
			return {
				cached_components: [
					'homeComponent',
					'articleComponent',
				]
			}
		},
		computed: {
			layout(){
				return this.$route.meta.layout + '-layout'
			}
		},
		created(){
			this.$emitter.on('set_cached_components', (data) => {
				this.cached_components = []
				this.cached_components.push(data + 'Component')
			})
		},
	}
</script>