<template>
    <Datepicker
        v-model="date"
        :format="format"
        :placeholder="placeholder"
        autoApply
        :closeOnAutoApply="false"
        :enableTimePicker="false"
        :min-date="min_date ? min_date : null"
        :max-date="max_date ? max_date : null"
        :clearable="clear"
        class="custom-date-input"
        @update:modelValue="select_date"
    />
</template>

<script>
    import Datepicker from '@vuepic/vue-datepicker'
    import '@vuepic/vue-datepicker/dist/main.css'
    import moment from 'moment'
    
    export default {
        components: { Datepicker },
        props: ['initial_value', 'placeholder', 'min_date', 'max_date', 'clear'],
        emits: ['change'],
        data() {
            return {
                date: null,
            };
        },
        watch: {
			initial_value: {
				deep: true,
				handler: function(newData) {
					this.date = new Date(this.$root.copy(newData))
				}
			},
		},
        methods: {
            format(){
                let start = moment(this.date).format("DD/MM/YYYY")

                return `${start}`
            },
			select_date(){
				this.$emit('change', this.date)
			},
        }
    }
</script>